<template>
  <form class="grid w-full" @submit="submitForm">
    <div class="grid gap-4">
      <Input type="email" name="email" :label="$t('auth.email')" required />
      <Input type="password" name="password" :label="$t('auth.password')" required is-secret />
      <NuxtLinkLocale
        to="/reset-password"
        class="ml-auto text-sm font-medium text-title-reversed underline underline-offset-4"
      >
        {{ $t("auth.forgotPassword.link") }}
      </NuxtLinkLocale>
    </div>
    <Button type="submit" :disabled="isSubmitting" class="mt-16">
      {{ $t("auth.login") }}
    </Button>
    <NuxtLinkLocale
      to="/register"
      class="mt-2 flex justify-center gap-3 rounded border border-[#efefef] bg-white px-[19px] py-[15px] text-center text-sm font-medium text-title-reversed underline decoration-transparent transition-all duration-300 hover:border-eerie-black hover:decoration-title-reversed"
    >
      {{ $t("auth.register") }}
    </NuxtLinkLocale>
  </form>
</template>
<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";

import { useChannel } from "~/src/channel/composables";
import { signInSchema } from "~/src/user/schemas";

const { tse } = useSaleorError();
const { channel } = useChannel();

const { signIn } = useAuth(channel);
const { handleSubmit, isSubmitting, setFieldError } = useForm({
  validationSchema: toTypedSchema(signInSchema),
});

const localePath = useLocalePath();

const { redirect } = useUrlSearchParams<{ redirect?: string }>();

const submitForm = handleSubmit(async (values) => {
  const res = await signIn(values);

  if (res.success) {
    if (redirect) {
      const fullPath = base62.decode(redirect);

      await navigateTo(`${fullPath}&updateEmail=true`);
    } else {
      await navigateTo(localePath("/profile"));
    }
  } else {
    res.error.forEach((e) => {
      if (e.field && (e.field === "email" || e.field === "password")) {
        setFieldError(e.field, tse(e));
      }
    });
  }
});
</script>
